import axios from "axios";
import {base_url, new_base_url} from "../components/Base_url";
import {auth} from "../components/Auth";

export const checkAvailableBuyTicket = () => {
    return axios({
        url: new_base_url() + "/get-config?config_slug=CAN_BUY_TICKET",
        method: "GET",
    }).then((response) => {
        return response.data.data;
    }).catch((err) => {
        return true
    });
}