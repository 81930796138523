import { useState, useEffect } from "react";
import axios from "axios";
import {base_url, new_base_url} from "../Base_url";
import {Movies, Premiere} from "../../interfaces";
import { auth } from "../Auth";

const Soon = () => {
    const [premiere, setPremiere] = useState<[] | Movies[]>([]);

    useEffect(() => {
        const fetchPremiere = async () => {
            await axios({
                url: new_base_url() + "/get_premiers",
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
                data: {}
            }).then((response) => {
                const data = response.data;
                setPremiere(data.response);
            }).catch((err) => {
                const status = err.response.status;
                if (status === 401) {
                    auth();
                    fetchPremiere();
                }
            });
        };
        fetchPremiere();
    }, [])

    return (
        <div className='soon-container'>
            <div id="releasesSoon" className="releases-soon ">
                {
                    premiere.length > 0 ?
                        <>
                            <div className="container releases-soon--title">
                                <h2 className="releases-list__title">Скоро в кино</h2>
                            </div>
                            <div className="container releases-container">
                                {premiere.map(item => (
                                    <a className="releases-item releases-item_soon" href={`soon/${item.filmId}`} key={item.filmId }>
                                        <div className="releases-item__poster releases-item__poster_soon gradient_4">
                                            <div className="releases-item__poster-height"></div>
                                            <img src={`${item.picture}`} className="promo-item__poster-img" alt={`Постер релиза ${item.filmName.replaceAll('kg', '')}`} />
                                            <div className="releases-item__age">{item.age}+</div>
                                            <div className="releases-item__badge"></div>
                                        </div>
                                        <div className="releases-item__info">
                                            <div className="releases-item__date-wrapper">
                                                <span className="releases-item__date">с {item.premiere_date}</span>
                                            </div>
                                            <div className="releases-item-description">
                                                <div className="releases-item-description__title releases-item-description__title_small">{item.filmName.replaceAll('kg', '')}</div>
                                            </div>
                                        </div>
                                    </a>
                                ))}
                            </div>
                        </>
                        :
                        <div className="no-seanses">
                            <p className="no-seanses__title">Сеансов не найдено</p>
                        </div>
                }
            </div>
        </div>
    )
}
export default Soon;