import { Link } from 'react-router-dom';
import axios from "axios";
import { base_url } from "../Base_url";
import { Navigate } from 'react-router-dom';

const Admin = () => {

    const handleClean = async () => {
        await axios({
            url: base_url() + "/api/v1/admin/clean",
            method: "GET",
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token"),
                'Content-Type': 'application/json'
            },
            data: {}
        }).then((response) => {
            if (response.status === 200) {
                console.log("clean success")
            }
        }).catch((err) => {
            const status = err.response.status;
            if (status === 401) {
                localStorage.setItem("isAuth", "false");
            }
        });
    };

    if (localStorage.getItem('isAuth') === "true") {
        return (
            <div className="login-container">
                {/* <Link to='/admin/admin_promo' className="btn_promo">Промо акции</Link> */}
                <Link to='/admin/admin_premiere' className="btn_promo">Премьеры</Link>
                <Link to='/admin/admin_background' className="btn_promo">Фон</Link>
                <Link to='/admin/admin_address' className="btn_promo">Адрес</Link>
                <Link to='/admin/admin_refund' className="btn_promo">Отмена платежа</Link>
                <Link to='/admin/advertisements' className="btn_promo">Реклама</Link>
                <button className="btn_promo" onClick={handleClean}>Очистить кэш</button>
            </div>
        );
    } else {
        return (
            <Navigate to="/login" />
        )
    }
};

export default Admin;
