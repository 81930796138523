import { useState, useEffect } from "react";
import { Form, Input, Button, Upload, message, DatePicker } from "antd";
import axios from "axios";
import { base_url } from "../Base_url";
import { Link } from 'react-router-dom';
import { UploadOutlined } from '@ant-design/icons';
import { UploadChangeParam } from 'antd/lib/upload/interface';
import { Premiere } from "../../interfaces";
import { Navigate } from 'react-router-dom';
import { auth } from "../Auth";

const { TextArea } = Input;

const AdminPremiere = () => {
    const [premiere, setPremiere] = useState<[] | Premiere[]>([]);
    const [form] = Form.useForm();
    const [imageData, setImageData] = useState<ArrayBuffer | null>(null);

    useEffect(() => {
        fetchPremiere();
    }, [])

    const fetchPremiere = async () => {
        await axios({
            url: base_url() + "/api/v1/admin/getPremier",
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            data: {}
        }).then((response) => {
            const data = response.data;
            setPremiere(data.response);
        }).catch((err) => {
            const status = err.response.status;
            if (status === 401) {
                localStorage.setItem("isAuth", "false");
            }
        });
    };

    const handleBeforeUpload = (file: File) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onloadend = () => {
            setImageData(reader.result as ArrayBuffer);
        };
        return false;
    };

    const handleUpload = (info: UploadChangeParam) => {
        const { status } = info.file;
        if (status === 'done') {
            message.success(`${info.file.name} uploaded successfully`);
        } else if (status === 'error') {
            message.error(`${info.file.name} upload failed.`);
        }
    };

    const handleSubmit = (values: any) => {
        const formData = new FormData();
        formData.append('filmName', values.filmName);
        formData.append('remark', values.remark);
        formData.append('age', values.age);
        formData.append('year', values.year);
        formData.append('period', values.period);
        formData.append('director', values.director);
        formData.append('country', values.country);
        formData.append('youtubeID', values.youtubeID);
        formData.append('genres', values.genres);
        formData.append('actors', values.actors);
        if (imageData) {
            const imageBlob = new Blob([imageData], { type: 'image/png' });
            formData.append('img', imageBlob);
            axios({
                url: base_url() + "/api/v1/admin/createPremiere",
                method: "POST",
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
            }).then((response) => {
                window.location.reload();
            }).catch((err) => {
                const status = err.response.status;
                if (status === 401) {
                    localStorage.setItem("isAuth", "false");
                }
            });
        }
    };

    const deletePremiere = async (id: number) => {
        await axios({
            url: base_url() + "/api/v1/admin/deletePremiere",
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            data: JSON.stringify({ id: id })
        }).then((response) => {
            window.location.reload();
        }).catch((err) => {
            const status = err.response.status;
            if (status === 401) {
                localStorage.setItem("isAuth", "false");
            }
        });
    };

    if (localStorage.getItem('isAuth') === "true") {
        return (
            <div className="login-container">
                <div className="promo_form_col_4">
                    <Form
                        form={form}
                        onFinish={handleSubmit} layout="vertical">
                        <Form.Item label="Название" name="filmName">
                            <Input id="title" />
                        </Form.Item>
                        <Form.Item label="Описание" name="remark">
                            <TextArea rows={4} />
                        </Form.Item>
                        <Form.Item label="Возрастное ограничение" name="age">
                            <Input id="age" />
                        </Form.Item>
                        <Form.Item name="period" label="Дата премьеры">
                            <DatePicker />
                        </Form.Item>
                        <Form.Item label="Режиссер" name="director">
                            <Input id="director" />
                        </Form.Item>
                        <Form.Item label="Страна" name="country">
                            <Input id="country" />
                        </Form.Item>
                        <Form.Item label="Год выпуска" name="year">
                            <Input id="year" />
                        </Form.Item>
                        <Form.Item label="Youtube ID" name="youtubeID">
                            <Input id="youtubeID" />
                        </Form.Item>
                        <Form.Item label="Жанры" name="genres">
                            <TextArea rows={4} />
                        </Form.Item>
                        <Form.Item label="Актеры" name="actors">
                            <TextArea rows={4} />
                        </Form.Item>
                        <Form.Item name="image">
                            <Upload
                                beforeUpload={handleBeforeUpload}
                                onChange={handleUpload}>
                                <Button icon={<UploadOutlined />}>Загрузить постер</Button>
                            </Upload>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="feedback__item">
                                Сохранить
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
                <div className="promo_list_col_8">
                    <ol>
                        {premiere.map(item => (
                            <div key={item.id}>
                                <li>
                                    <h4>{item.filmName}</h4>
                                    <p>{item.remark}</p>
                                    <Button danger onClick={() => deletePremiere(item.id)}>
                                        Удалить
                                    </Button>
                                    <Link to={`/admin/edit_premiere/${item.id}`}>
                                        <Button type="dashed">
                                            Обновить
                                        </Button>
                                    </Link>
                                </li>
                            </div>
                        ))}
                    </ol>
                </div>
            </div>
        );
    } else {
        return (
            <Navigate to="/login" />
        )
    }
};

export default AdminPremiere;
