import { useState, useEffect } from "react";
import axios from "axios";
import { base_url } from "../Base_url";
import * as ReactBootstrap from 'react-bootstrap';
import { Image } from "antd";
import { Promo } from "../../interfaces";
import { useLocation } from "react-router-dom";
import { auth } from "../Auth";

const PromoDetail = () => {
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    const [imageUrl, setImageUrl] = useState<string | "">("");
    const [promo, setPromo] = useState<Promo>();
    const [promoList, setPromoList] = useState<[] | Promo[]>([]);
    const [promoId, setPromoId] = useState<number>(-1);

    useEffect(() => {
        let id = location.pathname.split('/')[2];
        setPromoId(parseInt(id));
        getPromo(id);
        fetchPromo();
    }, [location])

    const getPromo = async (id: string) => {
        await axios({
            url: base_url() + "/api/v1/admin/getPromoById",
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            data: JSON.stringify({ id: id })
        }).then((response) => {
            const data = response.data;
            if (data.status === 0) {
                setPromo(data.response);
                setImageUrl(`data:image/jpeg;base64,${data.response.img}`)
            }
            setIsLoading(false);
        }).catch((err) => {
            const status = err.response.status;
            if (status === 401) {
                auth();
                getPromo(id);
            }
        });
    };

    const fetchPromo = async () => {
        await axios({
            url: base_url() + "/api/v1/admin/getPromo",
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            data: {}
        }).then((response) => {
            const data = response.data;
            setPromoList(data.response);
        }).catch((err) => {
            const status = err.response.status;
            if (status === 401) {
                auth();
                fetchPromo();
            }
        });
    };

    if (isLoading) {
        return (
            <div className="container">
                <ReactBootstrap.Spinner animation='grow' variant="success" className="spinner" />
            </div>
        );
    }

    return (
        <>
            <div className="promo">
                <div className="promo-container">
                    <div className="promo-info">
                        <div className="promo__title">{promo?.title}</div>
                        <div className="promo__description">
                            {imageUrl ? (
                                <>
                                    <Image src={imageUrl} /><br />
                                </>
                            ) : null}
                            <div className="promo__description__text">
                                <p>{promo?.description}</p>
                            </div>
                        </div>
                    </div>
                </div >
                {
                    promoList.length > 1 ?
                        <div className="news-others releases-list--white">
                            <div className="promo-container--others">
                                <h2 className="promo__title-others promo__title-others--black">Другие акции и скидки</h2>
                                <div className="promo__title-container">
                                    <a className="promo__title-link promo__title-link--black" href="/promo">
                                        <span>Показать все</span>
                                    </a>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="9" viewBox="0 0 12 9"
                                        className="icon icon-black icon--arrow-right">
                                        <path fillRule="evenodd" d="M1.41.84L6 5.42 10.59.84 12 2.25l-6 6-6-6z"></path>
                                    </svg>
                                </div>
                            </div>
                            <div className="promo-list">
                                <div className="promo-list-container">
                                    {promoList.map(item => (
                                        item.id !== promoId ?
                                            <a className="promo-item promo-item__others" href={`/promo/${item.id}`} key={item.id}>
                                                <div className="promo-item__poster">
                                                    <div className="promo-item__poster-height"></div>
                                                    <img src={`data:image/jpeg;base64,${item.img}`} className="promo-item__poster-img" alt="Постер акции" />
                                                </div>
                                                <div className="promo-item__info">
                                                    <div className="promo-item-description">
                                                        <div className="promo-item-description__title promo-item-description__title-others">{item.title}</div>
                                                        <div className="promo-item-description__period">{item.title}</div>
                                                    </div>
                                                </div>
                                            </a>
                                            : null
                                    ))}
                                </div>
                            </div>
                        </div>
                        : null
                }
            </div>
        </>
    )
}
export default PromoDetail;