import { Link } from 'react-router-dom';

const NotFound = () =>
    <div className='notfound-container'>
        <Link to="/">
            <div className="box404">
                <h1>Ошибка</h1>
                <div className="err">4</div>
                <i className="far fa-question-circle fa-spin"></i>
                <div className="err2">4</div>
            </div>
        </Link>
        <p><a href="/">Перейти на главную страницу</a></p>
    </div>

export default NotFound;