import { useState, useEffect, useCallback } from "react";
import { Form, Input, Button } from "antd";
import axios from "axios";
import { base_url } from "../Base_url";
import { Link } from 'react-router-dom';
import { Address } from "../../interfaces";
import { Navigate } from 'react-router-dom';
import { auth } from "../Auth";

const AdminAddress = () => {
    const [address, setAddress] = useState<[] | Address[]>([]);
    const [form] = Form.useForm();

    const fetchAddress = useCallback(() => {
        axios({
            url: base_url() + "/api/v1/admin/getAddresses",
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            data: {}
        }).then((response) => {
            const data = response.data;
            setAddress(data.response);
        }).catch((err) => {
            const status = err.response.status;
            if (status === 401) {
                localStorage.setItem("isAuth", "false");
            }
        });
    }, []);

    useEffect(() => {
        fetchAddress();
    }, [fetchAddress])

    const handleSubmit = (values: any) => {
        let data = {
            phone: values.phone,
            city: values.city,
            address: values.address,
            timeFrom: values.timeFrom,
            timeTo: values.timeTo,
            tiktok: values.tiktok,
            instagram: values.instagram,
            facebook: values.facebook,
            twitter: values.twitter,
            youtube: values.youtube,
            isActive: values.isActive
        }

        axios({
            url: base_url() + "/api/v1/admin/createAddress",
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            data: JSON.stringify(data)
        }).then((response) => {
            window.location.reload();
        }).catch((err) => {
            const status = err.response.status;
            if (status === 401) {
                localStorage.setItem("isAuth", "false");
            }
        });
    };

    const deleteAddress = async (id: number) => {
        await axios({
            url: base_url() + "/api/v1/admin/deleteAddress",
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            data: JSON.stringify({ id: id })
        }).then((response) => {
            window.location.reload();
        }).catch((err) => {
            const status = err.response.status;
            if (status === 401) {
                localStorage.setItem("isAuth", "false");
            }
        });
    };

    if (localStorage.getItem('isAuth') === "true") {
        return (
            <div className="login-container">
                <div className="promo_form_col_4">
                    <Form
                        form={form}
                        onFinish={handleSubmit} layout="vertical">
                        <Form.Item label="Номер телефона" name="phone">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Город" name="city">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Адрес" name="address">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Начало рабочего дня" name="timeFrom">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Конец рабочего дня" name="timeTo">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Tiktok" name="tiktok">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Instagram" name="instagram">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Facebook" name="facebook">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Twitter" name="twitter">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Youtube" name="youtube">
                            <Input />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="feedback__item">
                                Сохранить
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
                <div className="promo_list_col_8">
                    <ol>
                        {address.map(item => (
                            <div key={item.id}>
                                <li>
                                    <h4>{item.address}</h4>
                                    <Button danger onClick={() => deleteAddress(item.id)}>
                                        Удалить
                                    </Button>
                                    <Link to={`/admin/edit_address/${item.id}`}>
                                        <Button type="dashed">
                                            Обновить
                                        </Button>
                                    </Link>
                                </li>
                            </div>
                        ))}
                    </ol>
                </div>
            </div>
        );
    } else {
        return (
            <Navigate to="/login" />
        )
    }
};

export default AdminAddress;
