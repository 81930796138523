import { useState, useEffect, useCallback } from "react";
import { Button } from "antd";
import axios from "axios";
import { base_url } from "../Base_url";
import { Link } from 'react-router-dom';
import { Payments } from "../../interfaces";
import { Navigate } from 'react-router-dom';
import { auth } from "../Auth";

const AdminPayments = () => {
    const [payments, setPayments] = useState<[] | Payments[]>([]);

    const fetchPayments = useCallback(() => {
        axios({
            url: base_url() + "/api/v1/admin/getPayments",
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            data: {}
        }).then((response) => {
            const data = response.data;
            setPayments(data.response);
        }).catch((err) => {
            const status = err.response.status;
            if (status === 401) {
                localStorage.setItem("isAuth", "false");
            }
        });
    }, []);

    useEffect(() => {
        fetchPayments();
    }, [fetchPayments])

    if (localStorage.getItem('isAuth') === "true") {
        return (
            <div className="login-container">
                <div className="promo_list_col_8">
                    <ol>
                        {payments.map(item => (
                            <div key={item.id}>
                                <li>
                                    <p>
                                        {item.filmName}
                                        <p>
                                            <small>Дата покупки {item.createdDate}</small><br />
                                            <small>Время и дата сеанса {item.dateTime}</small><br />
                                            <small>Код платежа {item.pgPaymentId}</small><br />
                                            <small>Код заказа {item.pgOrderId}</small><br />
                                            <small>Код резерва {item.reservationId}</small>
                                        </p>
                                    </p>
                                    {
                                        item.status ?
                                            <Link to={`/admin/edit_refund/${item.id}`}>
                                                <Button type="dashed">
                                                    Обновить
                                                </Button>
                                            </Link>
                                            : null
                                    }
                                </li>
                            </div>
                        ))}
                    </ol>
                </div>
            </div>
        );
    } else {
        return (
            <Navigate to="/login" />
        )
    }
};

export default AdminPayments;
