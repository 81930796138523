import {Link, useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {new_base_url} from "../Base_url";
import * as ReactBootstrap from 'react-bootstrap';
import {Days, Movies, MovieSessions} from "../../interfaces/index";
import YouTube from 'react-youtube';
import {auth} from "../Auth";
import {checkAvailableBuyTicket} from "../../helper/config-helper";

const Release = () => {
    const location = useLocation();
    const navigate = useNavigate();
    let currentDate = new Date();
    const date = location.pathname.split('/')[2];
    const filmId = location.pathname.split('/')[3];
    let months = ['янв.', 'фев.', 'мар.', 'апр.', 'мая', 'июн.', 'июл.', 'авг.', 'сен.', 'окт.', 'ноя.', 'дек.'];
    let weekDay = ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'];
    let shortWeekDay = ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'];
    const [isLoading, setIsLoading] = useState(true);
    const [movie, setMovie] = useState<Movies>();
    const [activeDates, setActiveDates] = useState('');
    const [formattedDate, setFormattedDate] = useState('');
    const [dates, setDates] = useState<[] | Days[]>([]);
    const [weekDays, setWeekDays] = useState<[] | string[]>([]);
    const [shortWeekDays, setShortWeekDays] = useState<[] | string[]>([]);
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(true);
        fetchMovies(date);
    }, [location]);

    const fetchMovies = async (date: string) => {
        const dates: string[] = [];
        dates.push(date)
        await axios({
            url: new_base_url() + `/get_film/${filmId}?` + new URLSearchParams({
                'selected_date': date
            }),
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            data: {}
        }).then((response) => {
            const data = response.data;
            if (data.status === 0) {
                console.log(data.response)
                setMovie(data.response);
                setIsLoading(false);
            }
        }).catch((err) => {
            const status = err.response.status;
            if (status === 401) {
                auth();
                fetchMovies(date);
            }
            setIsLoading(false);
        });
    };
    const formattedText = (text: string) => {
        return text.split('\n').map((line, index) => (
            <React.Fragment key={index}>
                {line}
                <br/>
            </React.Fragment>
        ));
    }
    const lines = movie?.remark.split('\n\n').map((line, index) => (
        <React.Fragment key={index}>
            {line}
            <br/>
        </React.Fragment>
    ));
    const handleDateClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        setFormattedDate('');
        setActiveDates(event.currentTarget.id);
    };

    useEffect(() => {
        setFormattedDate(date);

        const fetchDates = async () => {
            await axios({
                url: new_base_url() + "/get_dates",
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
                data: JSON.stringify({filmId: filmId})
            }).then((response) => {
                const data = response.data;
                if (data.status === 0) {
                    listOfDates(data.response);
                    setDates(data.response);
                }
            }).catch((err) => {
                const status = err.response.status;
                if (status === 401) {
                    auth();
                    fetchDates();
                }
            });
        };

        fetchDates();
    }, []);

    const listOfDates = (data: Days[]) => {
        const arr: string[] = [];
        const shortArr: string[] = [];

        for (let i = 0; i < data.length; i++) {
            let el = data[i];
            const dateParts = el.date.split(".");
            const year = Number(dateParts[2]);
            const month = Number(dateParts[1]) - 1;
            const day = Number(dateParts[0]);
            const date = new Date(year, month, day);

            const tomorrow = new Date(currentDate);
            tomorrow.setDate(tomorrow.getDate() + 1);

            const tomorrowDate = tomorrow.toLocaleDateString();

            if (currentDate.toLocaleDateString() === date.toLocaleDateString()) {
                arr.push("Сегодня");
            } else if (tomorrowDate === date.toLocaleDateString()) {
                arr.push("Завтра");
            } else {
                let dayStr = weekDay[date.getDay()] + ', ' + (date.getDate()) + ' ' + months[date.getMonth()];
                arr.push(dayStr);
            }

            let shortDayStr = shortWeekDay[date.getDay()] + (date.getDate());
            shortArr.push(shortDayStr);
        }
        setWeekDays(arr);
        setShortWeekDays(shortArr);
    }

    const handleNavigation = async (e: React.MouseEvent<HTMLElement>, movie: MovieSessions) => {
        e.preventDefault()
        const available = await checkAvailableBuyTicket()
        if(!available) return
        // @ts-ignore
        window.ticketonNew.openShow(movie.ticketonId)
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    if (isLoading) {
        return (
            <div className="container">
                <ReactBootstrap.Spinner animation='grow' variant="success" className="spinner"/>
            </div>
        );
    }

    return (
        <>
            <div className='main'>
                {
                    movie ?
                        <div key={movie.filmId}>
                            <div className="breadcrumbs">
                                <div className="breadcrumbs-container container">
                                    <a className="breadcrumbs__link" href="/">Расписание залов</a>
                                    <span className="breadcrumbs__divider">»</span>
                                    <span className="breadcrumbs__page">{movie.filmName.replaceAll('kg', '')}</span>
                                </div>
                            </div>
                            <div className="release">
                                <div className="container release-container">
                                    <div className="release-left">
                                        <div className="release-poster ">
                                            <div className="release-poster__img-wrapper">
                                                <img
                                                    src={`${movie.picture}`}
                                                    className="release-poster__img" alt="Постер релиза"/>
                                            </div>
                                            <div className="release__age">{movie.age}</div>
                                            <div className="release__badge">
                                                {/* <div className="release__premiere">Премьера</div> */}
                                            </div>
                                        </div>
                                        <div className="release-rating">
                                        </div>
                                    </div>
                                    <div className="release-right">
                                        <div className="release-description">
                                            <div className="release__genre">{movie.genre}</div>
                                            <div className="release__title">{movie.filmName.replaceAll('kg', '')}</div>
                                        </div>
                                        <div className="release-schedule-wrap">
                                            <div className="calendar-menu">
                                                <div
                                                    className="calendar calendar-container calendar-menu--open calendar--release-page">
                                                    <div
                                                        className={`screeningDates-container calendar-menu ${open ? `calendar--open` : ``}`}>
                                                        {
                                                            dates.length > 0 ?
                                                                dates.map((date, index) => (
                                                                    index <= 5 ?
                                                                        <Link key={date.date}
                                                                              to={`/release/${date.date}/${movie.filmId}`}
                                                                              id={date.date} onClick={handleDateClick}
                                                                              className={`dateBtn dateBtn${activeDates === date.date || date.date === formattedDate ? "--active" : ""}`}>
                                                                            {weekDays[index]}
                                                                        </Link>
                                                                        :
                                                                        null
                                                                ))
                                                                :
                                                                <Link to="#"
                                                                      className="dateBtn dateBtn--active calendar__date--disabled">
                                                                    Сегодня
                                                                </Link>
                                                        }
                                                        {
                                                            dates.length < 5 ?
                                                                <button
                                                                    className={`calendar__date calendar__date--select-day ${open ? 'calendar__date--active' : ''}`}
                                                                    onClick={open ? handleClose : handleOpen}>Выбрать
                                                                    день
                                                                    <span className="calendar__arrow">
                                                                        <svg fill="currentColor"
                                                                             preserveAspectRatio="xMidYMid meet"
                                                                             height="24" width="24" viewBox="0 0 40 40"
                                                                             className="vertical-align: middle;">
                                                                            <g>
                                                                                <path
                                                                                    d="m12.3 13l7.7 7.7 7.7-7.7 2.3 2.4-10 10-10-10z"></path>
                                                                            </g>
                                                                        </svg>
                                                                    </span>
                                                                </button>
                                                                : null
                                                        }
                                                    </div>
                                                    {
                                                        open ?
                                                            <div className="calendar__days-list">
                                                                {dates.length > 0 ?
                                                                    dates.map((item, index) => (
                                                                        index >= 6 ?
                                                                            <Link key={index}
                                                                                  to={`/release/${item.date}/${filmId}`}
                                                                                  id={`${item.date}`}
                                                                                  onClick={handleDateClick}>
                                                                                <button data-is-button-disabled="true"
                                                                                        tabIndex={0}
                                                                                        className={`calendar-mobile__date ${shortWeekDays[index].substring(0, 2) === "СБ" || shortWeekDays[index].substring(0, 2) === "ВС"
                                                                                            ? "calendar-mobile__date--holiday" : ""}`}>
                                                                                    <span
                                                                                        className="calendar-mobile__date--day-week">{shortWeekDays[index].substring(0, 2)}</span>
                                                                                    <span
                                                                                        className="calendar-mobile__date--day-month">{shortWeekDays[index].substring(2, 4)}</span>
                                                                                </button>
                                                                            </Link>
                                                                            : null
                                                                    ))
                                                                    : null
                                                                }
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                            <div className="release-schedule">
                                                <div className="release-schedule__list">
                                                    <div className="release-schedule__item">
                                                        {movie.times.map(time => (
                                                            <div className="seance-item" key={time.sessionId}
                                                                 onClick={(e) => handleNavigation(e, time as unknown as MovieSessions)}>
                                                                <div className="seance-item__container">
                                                                    <div className="seance-item__item">
                                                                        <div
                                                                            className="seance-item__time">{time.time}</div>
                                                                        <div className="seance-item__wrapper">
                                                                            <div className="seance-item__description ">
                                                                                <span>{time.formatContent}</span>
                                                                                <span
                                                                                    className="seance-item--space"></span>
                                                                                <span>
                                                                                    {
                                                                                        time.price ?
                                                                                            time.price : null
                                                                                    } c
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {time.isHallVip ?
                                                                        <div className="seance-item__vip"></div>
                                                                        : null
                                                                    }
                                                                </div>
                                                                <div className="seance-item__hall">{time.hallName}</div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* {movie.picture.fileName !== undefined || movie.picture.fileName !== "" ?
                                            <div>
                                                <h2 className="releases-list__title">Трейлер</h2>
                                                <div className="release-trailer-list">
                                                    <video width="440" height="200" controls>
                                                        <source src={`https://api.nurcinema.kg/files/${movie.picture.fileName}.mp4`} type="video/mp4"
                                                            className="release-trailer-item" />
                                                        <img src="" className="release-trailer-item__poster" alt="" />
                                                        <div className="release-trailer-item__play">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="26" viewBox="0 0 22 26" className="icon icon-white">
                                                                <path fill="#FFF" fillRule="evenodd" stroke="#FFF" d="M.5 25V1l21 12z"></path>
                                                            </svg>
                                                        </div>
                                                    </video>
                                                </div>
                                            </div>
                                            : null} */}
                                        {
                                            movie.youtubeId ?
                                                <YouTube videoId={movie.youtubeId}
                                                         opts={{width: "300", height: "150"}}/>
                                                : null
                                        }
                                        {
                                            movie?.remark ?
                                                <div>
                                                    <h2 className="releases-list__title">О фильме</h2>
                                                    <div className="release__info">
                                                        <div className="release__text">
                                                            <div className="release__info-item__name">Описание</div>
                                                            <div className="release-description">{lines}</div>
                                                        </div>
                                                    </div>
                                                </div> : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null
                }
            </div>
        </>
    )
}
export default Release;
