import { useEffect, useState, useCallback } from "react";
import { Link, useLocation } from 'react-router-dom';
import axios from "axios";
import { base_url } from "../Base_url";
import Spinner from 'react-bootstrap/Spinner';
import { ApproveSendTicket } from "../../interfaces";

const SuccessPage = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const orderId = searchParams.get('order_id');
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [response, setResponse] = useState<ApproveSendTicket>();

    const fetchApproveSendTicket = useCallback(() => {
        axios({
            url: base_url() + `/api/v1/approveSendTicket?orderId=${orderId}`,
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            },
            data: {}
        }).then((response) => {
            if (response.status === 200) {
                setIsLoading(false);
                setResponse(response.data.response);
            }
        }).catch((err) => {
            console.log(err)
            setIsLoading(false);
        });
    }, [orderId])

    useEffect(() => {
        fetchApproveSendTicket();
    }, [fetchApproveSendTicket]);

    if (isLoading) {
        return (
            <div className="releases-list">
                <div className="container main-container">
                    <Spinner animation='grow' variant="success" className="spinner" />
                </div>
            </div>
        );
    }

    return (
        <div className="no-seanses">
            <p className="no-seanses__title">Транзакция прошла успешно!!</p>
            <p>Код транзакции: {orderId} </p>
            <p>Зал: {response?.hallName} </p>
            <p>Почта: {response?.email} </p>
            <p>Название фильма: {response?.filmName} </p>
            <p>Дата и время: {response?.dateTime} </p>
            <p>Код резерва: {response?.reservationId} </p>
            <p>Места: {response?.places.map(item => (
                "Ряд " + item.row + ", место " + item.place
            ))} </p>
            <a href={`https://api.nurcinema.kg/files/${response?.filmName}_${response?.orderId}.pdf`}>
                Скачать документ
            </a>
            <br />
            <button className="feedback__item">
                <Link to={`/`}>Посмотреть другие сеансы</Link>
            </button>
        </div>
    );
}

export default SuccessPage;