import '../../App.css';
import { Main } from '../Main';
import 'bootstrap/dist/css/bootstrap.css';

function Home() {
    return (
        <Main />
    )
}

export default Home;