// const apiUrl = `${process.env.REACT_APP_API_URL}`;
// const apiUrl = "http://localhost:8085";
const apiUrl = "https://api.nurcinema.kg";
// const apiUrl = "https://127.0.0.1:8000/api";
export const base_url = () => {
  return apiUrl;
}
export const new_base_url = () => {
  return "https://admin.nurcinema.kg/api";
  // return "http://127.0.0.1:8000/api";
}