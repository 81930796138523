import {useEffect, useState} from "react";
import axios from "axios";
import {new_base_url} from "../Base_url";

const About = () => {
    const [about, setAbout] = useState<{
        title: string
        description: string
        file_path: string
    }>()
    useEffect(() => {
        const fetchAbout = async () => {
            await axios({
                url: new_base_url() + "/vs/get_about_page",
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }).then((response) => {
                const data = response.data;
                setAbout(data.data)
            })
        };

        fetchAbout();
    }, []);
    return (

        <div className="main">
            <div className="content cinema">
                <div className="container cinema-container">
                    {about ?
                        <div className="cinema-info cinema-about-info">
                            <div className="cinema__title">
                                {about.title}
                            </div>
                            <p>
                                {about.description}
                            </p>
                            <br/>
                            <div className="se-component se-image-container __se__float-none">
                                <img className="lazyloaded" alt=""
                                     src={about.file_path ? `https://admin.nurcinema.kg/storage/page-images/${about.file_path}` : ''}/>
                            </div>
                            <br/><br/>
                        </div>
                        : ""
                    }
                </div>
            </div>
        </div>
    )
}
export default About;