import { useEffect, useState, useCallback } from "react";
import { Link, useLocation } from 'react-router-dom';
import { auth } from "../Auth";
import axios from "axios";
import { base_url } from "../Base_url";
import Spinner from 'react-bootstrap/Spinner';
import '../../Plan.css';

const FailurePage = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const orderId = searchParams.get('order_id');
    const pgOrderId = searchParams.get('pg_order_id');
    const pgPaymentId = searchParams.get('pg_payment_id');
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const fetchFailedSaleOrderId = useCallback(() => {
        axios({
            url: base_url() + `/api/v1/failedSaleOrderId`,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            data: JSON.stringify({
                orderId: orderId,
                pgOrderId: pgOrderId,
                pgPaymentId: pgPaymentId
            })
        }).then((response) => {
            if (response.status === 200) {
                setIsLoading(false);
            }
        }).catch((err) => {
            const status = err.response.status;
            if (status === 401) {
                auth();
                fetchFailedSaleOrderId();
            }
            setIsLoading(false);
        });
    }, [orderId, pgOrderId, pgPaymentId])

    useEffect(() => {
        fetchFailedSaleOrderId();
    }, [fetchFailedSaleOrderId]);



    if (isLoading) {
        return (
            <div className="releases-list">
                <div className="container main-container">
                    <Spinner animation='grow' variant="success" className="spinner" />
                </div>
            </div>
        );
    }

    return (
        <div className="no-seanses">
            <p className="no-seanses__title">Не удалось завершить транзакцию!!</p>
            <p>Код транзакции: {orderId} </p>
            <br />
            <button className="feedback__item">
                <Link to={`/`}>Попробуйте еще раз</Link>
            </button>
        </div>
    );
}

export default FailurePage;