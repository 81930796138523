import { base_url } from './Base_url';
import axios from "axios";

export const auth = () => {
  const fetchToken = async () => {
    await axios({
      url: base_url() + "/api/authenticate",
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({ "email": "broadway$dsR4admin@gmail.com", "password": "Ln>)h%@6fM43!U" })
    }).then((response) => {
      const data = response.data.token;
      localStorage.setItem("token", data);
    }).catch((err) => {
      // console.log(err);
    });
  };

  return fetchToken();
}