import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
import {base_url, new_base_url} from "../Base_url";
import * as ReactBootstrap from 'react-bootstrap';
import {Movies, Premiere} from "../../interfaces/index";
import YouTube from 'react-youtube';
import { auth } from "../Auth";

const SoonDetails = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [premiereDetails, setPremiereDetails] = useState<Movies>();
    const location = useLocation();
    const id = location.pathname.split('/')[2];
    const [premiere, setPremiere] = useState<[] | Movies[]>([]);

    useEffect(() => {
        const fetchPremiere = async () => {
            await axios({
                url: new_base_url() + `/get_premiers`,
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
                data: {}
            }).then((response) => {
                const data = response.data;
                setPremiere(data.response);
            }).catch((err) => {
                const status = err.response.status;
                if (status === 401) {
                    auth();
                    fetchPremiere();
                }
            });
        };
        fetchPremiere();
    }, [])

    useEffect(() => {
        setIsLoading(true);
        const fetchPremiereById = async (id: string) => {
            await axios({
                url: new_base_url() + `/get_premier/${id}`,
                method: "get",
                headers: {
                    "Content-Type": "application/json",
                },
            }).then((response) => {
                const data = response.data;
                setPremiereDetails(data.response);
                setIsLoading(false);
            }).catch((err) => {
                const status = err.response.status;
                if (status === 401) {
                    auth();
                    fetchPremiereById(id);
                }
                setIsLoading(false);
            });
        };

        fetchPremiereById(id);
    }, [location]);

    if (isLoading) {
        return (
            <div className="container">
                <ReactBootstrap.Spinner animation='grow' variant="success" className="spinner" />
            </div>
        );
    }

    const lines = premiereDetails?.remark.split('\n\n').map((line, index) => (
        <React.Fragment key={index}>
            {line}
            <br/>
        </React.Fragment>
    ));

    return (
        <>
            <div className='main'>
                <div key={premiereDetails?.filmId}>
                    <div className="breadcrumbs">
                        <div className="breadcrumbs-container container">
                            <a className="breadcrumbs__link" href="/">Расписание залов</a>
                            <span className="breadcrumbs__divider">»</span>
                            <span className="breadcrumbs__page">{premiereDetails?.filmName.replaceAll('kg', '')}</span>
                        </div>
                    </div>
                    <div className="release">
                        <div className="container release-container">
                            <div className="release-left">
                                <div className="release-poster ">
                                    <div className="release-poster__img-wrapper">
                                        <img src={`${premiereDetails?.picture}`} className="promo-item__poster-img" alt={`Постер релиза ${premiereDetails?.filmName.replaceAll('kg', '')}`} />
                                    </div>
                                    <div className="release__age">{premiereDetails?.age}</div>
                                    <div className="release__badge">
                                    </div>
                                </div>
                            </div>
                            <div className="release-right">
                                <div className="release-description">
                                    <div className="release__genre">
                                        <span style={{ marginRight: "10px" }}>{premiereDetails?.age}</span>
                                    </div>
                                    <div className="release__title">{premiereDetails?.filmName.replaceAll('kg', '')}</div>
                                </div>
                                <div className="release__info" style={{ textAlign: "center" }}>
                                    В кино с {premiereDetails?.premiere_date}
                                </div><br />
                                {/*{*/}
                                {/*    premiereDetails?.youtubeID ?*/}
                                {/*        <div className="release-trailer-list">*/}
                                {/*            <YouTube videoId={premiereDetails?.youtubeID} opts={{ width: "300", height: "150" }} />*/}
                                {/*        </div>*/}
                                {/*        : null*/}
                                {/*}*/}
                                <div className="release__info">
                                    <div className="release__text">
                                        <div className="release__info-item__name">Описание</div>
                                        <div className="release-description">{lines}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="releases-soon ">
                <div className="container releases-soon--title">
                    <h2 className="releases-list__title">Скоро в кино</h2>
                </div>
                <div className="container releases-container">
                    {premiere.map(item => (
                        item.filmId !== parseInt(id, 10) ?
                            <a className="releases-item releases-item_soon" href={`${item.filmId}`} key={item.filmId}>
                                <div className="releases-item__poster releases-item__poster_soon gradient_4">
                                    <div className="releases-item__poster-height"></div>
                                    <img src={`${item.picture}`} className="promo-item__poster-img" alt={`Постер релиза ${item.filmName.replaceAll('kg', '')}`} />
                                    <div className="releases-item__age">{item.age}</div>
                                    <div className="releases-item__badge"></div>
                                </div>
                                <div className="releases-item__info">
                                    <div className="releases-item__date-wrapper">
                                        <span className="releases-item__date">с {item.premiere_date}</span>
                                    </div>
                                    <div className="releases-item-description">
                                        <div className="releases-item-description__title releases-item-description__title_small">{item.filmName.replaceAll('kg', '')}</div>
                                    </div>
                                </div>
                            </a>
                            : null
                    ))}
                </div>
            </div>
        </>
    )
}
export default SoonDetails;
