import { useState, useEffect } from "react";
import axios from "axios";
import { base_url } from "../Base_url";
import Soon from "./Soon";
import { Promo } from "../../interfaces";
import { auth } from "../Auth";

const PromoPage = () => {
    const [promo, setPromo] = useState<[] | Promo[]>([]);

    useEffect(() => {
        const fetchPromo = async () => {
            await axios({
                url: base_url() + "/api/v1/admin/getPromo",
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
                data: {}
            }).then((response) => {
                const data = response.data;
                setPromo(data.response);
            }).catch((err) => {
                const status = err.response.status;
                if (status === 401) {
                    auth();
                    fetchPromo();
                }
            });
        };
        fetchPromo();
    }, [])

    return (
        <>
            <div className="promo">
                <div className="promo-container">
                    <div className="promo-info">
                        <div className="promo__title">Акции и скидки</div>
                        <div className="promo-items">
                            {promo.map(item => (
                                <a className="promo-item" href={`/promo/${item.id}`} key={item.id}>
                                    <div className="promo-item__poster">
                                        <div className="promo-item__poster-height"></div>
                                        <img src={`data:image/jpeg;base64,${item.img}`} className="promo-item__poster-img" alt="Постер акции" />
                                    </div>
                                    <div className="promo-item__info">
                                        <div className="promo-item-description">
                                            <div className="promo-item-description__title">{item.title}</div>
                                            <div className="promo-item-description__period">{item.title}</div>
                                        </div>
                                    </div>
                                </a>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default PromoPage;