import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInstagram, faTiktok} from '@fortawesome/free-brands-svg-icons';
import {useEffect, useState} from "react";
import axios from "axios";
import {new_base_url} from "../Base_url";

const Contacts = () => {
    interface Data {
        title: string;
        map_link: string;
        content: Content[];
    }

    interface Content {
        title: string;
        slug: string;
        list: List[];
    }

    interface List {
        title: string;
        href?: string;
    }

    const [contact, setContact] = useState<Data>()
    useEffect(() => {
        const fetchAbout = async () => {
            await axios({
                url: new_base_url() + "/vs/get_contact_page",
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }).then((response) => {
                const data = response.data;
                setContact(data.data)
            })
        };

        fetchAbout();
    }, []);
    return (
        <div className="cinema-info-item__contacts">
            {
                contact ?
                    <div>
                        <h2 className="cinema-info-item__cinema-name">{contact.title}</h2>
                        <div className="cinema-info-item__map">
                            <div className="cinema-info-item__map-container">
                                <iframe
                                    src={contact.map_link}
                                    width="100%" height="300" loading="lazy" referrerPolicy="no-referrer-when-downgrade"
                                    title="map"></iframe>
                            </div>
                        </div>
                        <div className="cinema-info-item__contacts-wrapper">
                            {
                                contact.content.map((item) => (
                                    <div  className="cinema-info-item__contacts-field">
                                        <span>{item.title}</span>
                                        {
                                            item.list.map((elem) => (
                                                <a className="cinema-info-item__contacts-link" href={elem.href}
                                                   target="_blank"
                                                   rel="noopener noreferrer">
                                                    <span>{elem.title}</span>
                                                </a>
                                            ))
                                        }
                                    </div>
                                ))
                            }
                            {/*<div className="cinema-info-item__contacts-field">*/}
                            {/*    <h3 className="cinema-info-item__subtitle">Контакты</h3>*/}
                            {/*    <div className="cinema-info-item__contacts-phone">*/}
                            {/*        <span>Касса:</span><br/>*/}
                            {/*        <a className="cinema-info-item__contacts-link" href="tel:0551566444" target="_blank"*/}
                            {/*           rel="noopener noreferrer">*/}
                            {/*            <span>0551 56 64 44</span><br/>*/}
                            {/*        </a>*/}
                            {/*        <a className="cinema-info-item__contacts-link" href="tel:0771556444" target="_blank"*/}
                            {/*           rel="noopener noreferrer">*/}
                            {/*            <span>0771 55 64 44</span>*/}
                            {/*        </a>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className="cinema-info-item__contacts-field">*/}
                            {/*    <h3 className="cinema-info-item__subtitle">Адрес</h3>*/}
                            {/*    <span>Ош</span>*/}
                            {/*    <br/>*/}
                            {/*    <span>пр. Ленина 212</span>*/}
                            {/*</div>*/}
                            {/*<div className="cinema-info-item__contacts-field">*/}
                            {/*    <h3 className="cinema-info-item__subtitle">Режим работы</h3>*/}
                            {/*    <span>Ежедневно с 09:00 до 02:00</span>*/}
                            {/*</div>*/}
                        </div>
                        <div className="cinema-info-item__socials-wrapper">
                            <h3 className="cinema-info-item__subtitle">Социальные сети</h3>
                            <div className="cinema-info-item__social-links">
                                <a className='social-icon-link tiktok'
                                   href='https://www.tiktok.com/@nurcinema_kg'
                                   target='_blank' rel="noreferrer"
                                   aria-label="tiktok">
                                    <FontAwesomeIcon icon={faTiktok}/>
                                </a>
                                <a className='social-icon-link instagram'
                                   href='https://www.instagram.com/nurcinema_kg'
                                   target='_blank' rel="noreferrer"
                                   aria-label="instagram">
                                    <FontAwesomeIcon icon={faInstagram}/>
                                </a>
                            </div>
                        </div>
                    </div> : ""
            }
        </div>
    )
}
export default Contacts;