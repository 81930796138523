import { useState } from "react";
import { Link } from 'react-router-dom';

const Navbar = () => {
  let currentDate = new Date();
  const day = currentDate.getDate().toString().padStart(2, '0');
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const year = currentDate.getFullYear().toString();

  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const [activeLink, setActiveLink] = useState("home");
  const [date, setCurrentDate] = useState(`${day}.${month}.${year}`);

  const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    setClick(false);
    setActiveLink(event.currentTarget.id);
    if (event.currentTarget.id === "screenings" || event.currentTarget.id === "home") {
      setCurrentDate(`${day}.${month}.${year}`);
    }
  };

  return (
    <>
      <header className='header'>
        <Link to={`/${date}`} className='header-logo' onClick={handleLinkClick}></Link>
        <div className='menu-icon' onClick={handleClick}>
          <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
        </div>
        <nav className={click ? 'menu active' : 'menu menu--navigation'} >
          <div className="menu-container">
            <Link to={`/${date}`} onClick={handleLinkClick} id="home" className={`menu__item menu__item${activeLink === "home" ? "--active" : ""}`}>Афиша</Link>
            <Link to={`/screenings/${date}`} id="screenings" className={`menu__item menu__item${activeLink === "screenings" ? "--active" : ""}`} onClick={handleLinkClick}>
              Сеансы
            </Link>
            <Link to='/soon' id="soon" className={`menu__item menu__item${activeLink === "soon" ? "--active" : ""}`} onClick={handleLinkClick}>Скоро в кино</Link>
            {/* <Link to='/promo' id="promo" className={`menu__item menu__item${activeLink === "promo" ? "--active" : ""}`} onClick={handleLinkClick}>Акции и скидки</Link> */}
            <Link to='/about' id="about" className={`menu__item menu__item${activeLink === "about" ? "--active" : ""}`} onClick={handleLinkClick}>О кинотеатре</Link>
            <Link to='/contacts' id="contacts" className={`menu__item menu__item${activeLink === "contacts" ? "--active" : ""}`} onClick={handleLinkClick}>Контакты</Link>
            {/* <div className="feedback">
              <button className="feedback__item" onClick={() => setIsOpenFeedback(true)}>Написать отзыв</button>
              {isOpenFeedback ?
                <Feedback handleClose={() => setIsOpenFeedback(false)} />
                : null}
            </div> */}
          </div>
        </nav>
      </header>
    </>
  )
}
export default Navbar;


