import { useState, useEffect, useCallback } from "react";
import { Form, Input, Button, Select } from "antd";
import axios from "axios";
import { base_url } from "../Base_url";
import { Navigate, useLocation } from 'react-router-dom';
import { auth } from "../Auth";

const EditAddress = () => {
    const [form] = Form.useForm();
    const location = useLocation();
    const [isSubmit, setSubmit] = useState(false);
    const idPath = location.pathname.split('/')[3];

    const getAddress = useCallback(() => {
        axios({
            url: base_url() + "/api/v1/admin/getAddressById",
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            data: JSON.stringify({ id: parseInt(idPath) })
        }).then((response) => {
            const data = response.data;
            if (data.status === 0) {
                form.setFieldsValue({ phone: data.response.phone })
                form.setFieldsValue({ city: data.response.city })
                form.setFieldsValue({ address: data.response.address })
                form.setFieldsValue({ timeFrom: data.response.timeFrom })
                form.setFieldsValue({ timeTo: data.response.timeTo })
                form.setFieldsValue({ tiktok: data.response.tiktok })
                form.setFieldsValue({ instagram: data.response.instagram })
                form.setFieldsValue({ facebook: data.response.facebook })
                form.setFieldsValue({ twitter: data.response.twitter })
                form.setFieldsValue({ youtube: data.response.youtube })
                form.setFieldsValue({ isActive: data.response.isActive })
            }
        }).catch((err) => {
            const status = err.response.status;
            if (status === 401) {
                localStorage.setItem("isAuth", "false");
            }
        });
    }, [form, idPath]);

    useEffect(() => {
        getAddress();
    }, [getAddress])

    const handleSubmit = async (values: any) => {
        let data = {
            id: parseInt(idPath),
            phone: values.phone,
            city: values.city,
            address: values.address,
            timeFrom: values.timeFrom,
            timeTo: values.timeTo,
            tiktok: values.tiktok,
            instagram: values.instagram,
            facebook: values.facebook,
            twitter: values.twitter,
            youtube: values.youtube,
            isActive: values.isActive
        }

        await axios({
            url: base_url() + "/api/v1/admin/changeAddressStatus",
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            data: JSON.stringify(data)
        }).then((response) => {
            setSubmit(true);
        }).catch((err) => {
            const status = err.response.status;
            if (status === 401) {
                localStorage.setItem("isAuth", "false");
            }
        });
    };

    if (localStorage.getItem('isAuth') === "true") {
        if (isSubmit) {
            return <Navigate to="/admin/admin_address" />
        }

        return (
            <div className="login-container">
                <div className="promo_form_col_4">
                    <Form
                        form={form}
                        onFinish={handleSubmit} layout="vertical">
                        <Form.Item label="Номер телефона" name="phone">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Город" name="city">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Адрес" name="address">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Начало рабочего дня" name="timeFrom">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Конец рабочего дня" name="timeTo">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Tiktok" name="tiktok">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Instagram" name="instagram">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Facebook" name="facebook">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Twitter" name="twitter">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Youtube" name="youtube">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Статус" name="isActive">
                            <Select>
                                <Select.Option value={1}>Активировать</Select.Option>
                                <Select.Option value={2}>Отключить</Select.Option>
                            </Select>
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="feedback__item">
                                Сохранить
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div >
        );
    } else {
        return (
            <Navigate to="/login" />
        )
    }
};

export default EditAddress;
