import { Link } from 'react-router-dom';
import '../../Plan.css';

const Error = () => {

    return (
        <div className="no-seanses">
            <p className="no-seanses__title">Что-то пошло не так!!</p>
            <br />
            <button className="feedback__item">
                <Link to={`/`}>Попробуйте еще раз</Link>
            </button>
        </div>
    );
}

export default Error;