import React, { useState } from "react";
import { Form, Input, Button } from "antd";
import axios from "axios";
import { base_url } from "../../components/Base_url";
import * as ReactBootstrap from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import { auth } from "../Auth";

const LoginForm = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [isAuth, setIsAuth] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [form] = Form.useForm();

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    };

    const handleSubmit = () => {
        setIsSubmit(true);
        axios({
            url: base_url() + "/api/authenticate",
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            data: JSON.stringify({ password: password, email: email })
        }).then((response) => {
            const data = response.data;
            if (data.token !== undefined) {
                setIsAuth(true);
                localStorage.setItem('isAuth', "true");
            } else {
                localStorage.setItem('isAuth', "false");
            }
            setIsLoading(false);
        }).catch((err) => {
            setIsLoading(false);
        });
    };

    if (isSubmit && isLoading) {
        return (
            <div className="container">
                <ReactBootstrap.Spinner animation='grow' variant="success" className="spinner" />
            </div>
        );
    }

    if (!isAuth) {
        return (
            <div className="login-container">
                <Form
                    form={form}
                    onFinish={handleSubmit} layout="vertical">
                    <Form.Item label="Email">
                        <Input value={email} onChange={handleEmailChange} />
                    </Form.Item>
                    <Form.Item label="Пароль">
                        <Input.Password value={password} onChange={handlePasswordChange} />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="feedback__item">
                            Войти
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        );
    }

    return (
        <Navigate to="/admin/index" />
    )
};

export default LoginForm;
