import { useState, useEffect, useCallback } from "react";
import { Form, Input, Button, Upload, message, Select } from "antd";
import axios from "axios";
import { base_url } from "../Base_url";
import { Link } from 'react-router-dom';
import { Advertisements } from "../../interfaces";
import { Navigate } from 'react-router-dom';
import { UploadOutlined } from '@ant-design/icons';
import { UploadChangeParam } from 'antd/lib/upload/interface';
import { auth } from "../Auth";

const AdminAdvertisements = () => {
    const [advertisement, setAdvertisements] = useState<[] | Advertisements[]>([]);
    const [form] = Form.useForm();
    const [imageData, setImageData] = useState<ArrayBuffer | null>(null);

    const fetchAdvertisements = useCallback(() => {
        axios({
            url: base_url() + "/api/v1/admin/getAdvertisement",
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            data: {}
        }).then((response) => {
            const data = response.data;
            setAdvertisements(data.response);
        }).catch((err) => {
            const status = err.response.status;
            if (status === 401) {
                localStorage.setItem("isAuth", "false");
            }
        });
    }, []);

    useEffect(() => {
        fetchAdvertisements();
    }, [fetchAdvertisements])

    const handleSubmit = (values: any) => {
        const formData = new FormData();
        formData.append('name', values.name);
        formData.append('src', values.name);
        formData.append("is_active", values.is_active);
        if (imageData) {
            const imageBlob = new Blob([imageData], { type: 'image/png' });
            formData.append('img', imageBlob);
            axios({
                url: base_url() + "/api/v1/admin/createAdvertisement",
                method: "POST",
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
            }).then((response) => {
                window.location.reload();
            }).catch((err) => {
                const status = err.response.status;
                if (status === 401) {
                    localStorage.setItem("isAuth", "false");
                }
            });
        }
    };

    const deleteAdvertisement = async (id: number) => {
        await axios({
            url: base_url() + "/api/v1/admin/deleteAdvertisement",
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            data: JSON.stringify({ id: id })
        }).then((response) => {
            window.location.reload();
        }).catch((err) => {
            const status = err.response.status;
            if (status === 401) {
                localStorage.setItem("isAuth", "false");
            }
        });
    };

    const handleBeforeUpload = (file: File) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onloadend = () => {
            setImageData(reader.result as ArrayBuffer);
        };
        return false;
    };

    const handleUpload = (info: UploadChangeParam) => {
        const { status } = info.file;
        if (status === 'done') {
            message.success(`${info.file.name} uploaded successfully`);
        } else if (status === 'error') {
            message.error(`${info.file.name} upload failed.`);
        }
    };

    if (localStorage.getItem('isAuth') === "true") {
        return (
            <div className="login-container">
                <div className="promo_form_col_4">
                    <Form
                        form={form}
                        onFinish={handleSubmit} layout="vertical">
                        <Form.Item label="Название" name="name">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Статус" name="is_active">
                            <Select>
                                <Select.Option value={1}>Активировать</Select.Option>
                                <Select.Option value={2}>Отключить</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item name="image">
                            <Upload
                                beforeUpload={handleBeforeUpload}
                                onChange={handleUpload}>
                                <Button icon={<UploadOutlined />}>Загрузить постер</Button>
                            </Upload>
                        </Form.Item>
                        <Form.Item label="Ссылка" name="src">
                            <Input />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="feedback__item">
                                Сохранить
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
                <div className="promo_list_col_8">
                    <ol>
                        {advertisement.map(item => (
                            <div key={item.id}>
                                <li>
                                    <h4>{item.name}</h4>
                                    <Button danger onClick={() => deleteAdvertisement(item.id)}>
                                        Удалить
                                    </Button>
                                    <Link to={`/admin/edit_advertisement/${item.id}`}>
                                        <Button type="dashed">
                                            Обновить
                                        </Button>
                                    </Link>
                                </li>
                            </div>
                        ))}
                    </ol>
                </div>
            </div>
        );
    } else {
        return (
            <Navigate to="/login" />
        )
    }
};

export default AdminAdvertisements;
