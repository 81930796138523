import { useState, useEffect, useCallback } from "react";
import { Form, Input, Button } from "antd";
import axios from "axios";
import { base_url } from "../Base_url";
import { Navigate, useLocation } from 'react-router-dom';

const EditPayments = () => {
    const [form] = Form.useForm();
    const location = useLocation();
    const [isSubmit, setSubmit] = useState(false);
    const idPath = location.pathname.split('/')[3];
    const [message, setMessage] = useState<string>("");

    const getPayment = useCallback(() => {
        axios({
            url: base_url() + "/api/v1/admin/getPaymentById",
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            data: JSON.stringify({ orderId: parseInt(idPath) })
        }).then((response) => {
            const data = response.data;
            if (data.status === 0) {
                form.setFieldsValue({ amount: data.response.amount })
                form.setFieldsValue({ filmName: data.response.filmName })
            }
        }).catch((err) => {
            const status = err.response.status;
            if (status === 401) {
                localStorage.setItem("isAuth", "false");
            }
        });
    }, [form, idPath]);

    useEffect(() => {
        getPayment();
    }, [getPayment])

    const handleSubmit = async (values: any) => {
        let data = {
            orderId: parseInt(idPath),
            amount: values.amount,
            reason: values.reason
        }

        await axios({
            url: base_url() + "/api/v1/admin/refundTicketon",
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            data: JSON.stringify(data)
        }).then((response) => {
            if (response.status === 200) {
                if (response.data.status !== 25) {
                    setSubmit(true);
                } else {
                    setMessage("Такой оплаты нет");
                }
            }
        }).catch((err) => {
            const status = err.response.status;
            if (status === 401) {
                localStorage.setItem("isAuth", "false");
            }
        });
    };

    if (localStorage.getItem('isAuth') === "true") {
        if (isSubmit) {
            return <Navigate to="/admin/admin_refund" />
        }

        return (
            <div className="login-container">
                <div className="promo_form_col_4">
                    <Form
                        form={form}
                        onFinish={handleSubmit} layout="vertical">
                        <p style={{ color: "red" }}>{message}</p>
                        <Form.Item label="Название фильма" name="filmName">
                            <Input readOnly />
                        </Form.Item>
                        <Form.Item label="Сумма" name="amount">
                            <Input readOnly />
                        </Form.Item>
                        <Form.Item label="Причина" name="reason">
                            <Input />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="feedback__item">
                                Вернуть платеж
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div >
        );
    } else {
        return (
            <Navigate to="/login" />
        )
    }
};

export default EditPayments;
