import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';

function Footer() {
  let currentDate = new Date();
  const day = currentDate.getDate().toString().padStart(2, '0');
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const year = currentDate.getFullYear().toString();

  return (
    <div className="footer-container">
      <div className="footer">
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2 className='footer-title'>Меню</h2>
            <Link to='/' className='footer_links-nav'>Афиша</Link>
            <Link to={`/screenings/${day}.${month}.${year}`} className='footer_links-nav'>Сеансы</Link>
            <Link to='/soon' className='footer_links-nav'>Скоро в кино</Link>
            {/* <Link to='/promo' className='footer_links-nav'>Акции и скидки</Link> */}
            <Link to='/about' className='footer_links-nav'>О кинотеатре</Link>
            <Link to='/contacts' className='footer_links-nav'>Контакты</Link>
          </div>
          <div className='footer-link-items'>
            <h2 className='footer-title'>Способ оплаты</h2>
            <div className='payments'>
              <img src='/image/visa.svg' alt="visa icon" />
              <img src='/image/mastercard.svg' alt="mastercard icon" />
              <img src='/image/maestro.svg' alt="maestro icon" />
            </div><br />
            <h2 className='footer-title'>Социальные сети</h2>
            <div className='social-icons'>
              <a
                className='social-icon-link tiktok'
                href='https://www.tiktok.com/@nurcinema_kg'
                target='_blank' rel="noreferrer"
                aria-label="tiktok">
                <FontAwesomeIcon icon={faTiktok} />
              </a>
              <a
                className='social-icon-link instagram'
                href='https://www.instagram.com/nurcinema_kg'
                target='_blank' rel="noreferrer"
                aria-label="instagram">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </div>
          </div>
          <div className='footer-link-items'>
            <h2 className='footer-title'>Время работы</h2>
            <p>Ежедневно с 09:00 до 02:00</p>
          </div>
          <div className='footer-link-items'>
            <h2 className='footer-title'>Контакты</h2>
            <a href="https://2gis.kg/osh/firm/70000001030858664?m=72.808399%2C40.514889%2F16"
              target="_blank" rel='noreferrer'>
              <p>Ош, пр. Ленина 212</p>
            </a>
            <p><a href='tel:+996(551)56-64-44'><i className='fas fa-phone-alt' /> +996 (551) 56 64 44</a></p>
            <p><a href='tel:+996(771)55-64-44'><i className='fas fa-phone-alt' /> +996 (771) 55 64 44</a></p>
          </div>
        </div>
      </div>
      <div className='footer-rights'>
        <p> © {year}. Все права защищены</p>
      </div>
      <br />
      <div className='footer-notice'>
        <p>Все сеансы начинаются с рекламно-информационного блока.</p>
        <p>Точную продолжительность сеансов можно уточнить в кинотеатре.</p>
      </div>
    </div>
  );
}

export default Footer;
