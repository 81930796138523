import { useState, useEffect, useCallback } from "react";
import { Form, Input, Button, Upload, message, Image, Select } from "antd";
import axios from "axios";
import { base_url } from "../Base_url";
import { Navigate, useLocation } from 'react-router-dom';
import { UploadOutlined } from '@ant-design/icons';
import { UploadChangeParam } from 'antd/lib/upload/interface';
import type { UploadFile } from 'antd/es/upload/interface';
import { auth } from "../Auth";

const EditBackground = () => {
    const [form] = Form.useForm();
    const [id, setId] = useState<number>(0);
    const location = useLocation();
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const [imageData, setImageData] = useState<ArrayBuffer | null>(null);
    const [isSubmit, setSubmit] = useState(false);
    const [fileList, setFileList] = useState<UploadFile[]>([
        {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: '',
        }])

    const readFile = (url: string) => {
        return new Promise<ArrayBuffer>((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', url);
            xhr.responseType = 'arraybuffer';
            xhr.onload = () => {
                if (xhr.status === 200) {
                    resolve(xhr.response);
                } else {
                    reject(new Error(`Failed to load ${url}: ${xhr.status} ${xhr.statusText}`));
                }
            };
            xhr.onerror = () => {
                reject(new Error(`Failed to load ${url}`));
            };
            xhr.send();
        });
    };

    const getBackground = useCallback(() => {
        axios({
            url: base_url() + "/api/v1/admin/getBackgroundById",
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            data: JSON.stringify({ id: id })
        }).then((response) => {
            const data = response.data;
            if (data.status === 0) {
                form.setFieldsValue({ bgTitle: data.response.bgTitle })
                form.setFieldsValue({ isActive: data.response.isActive })
                setImageUrl(`data:image/jpeg;base64,${data.response.img}`)
                readFile(`data:image/jpeg;base64,${data.response.img}`)
                    .then((buffer) => {
                        setImageData(buffer);
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        }).catch((err) => {
            const status = err.response.status;
            if (status === 401) {
                localStorage.setItem("isAuth", "false");
            }
        });
    }, [form, id]);

    useEffect(() => {
        const idPath = location.pathname.split('/')[3];
        setId(parseInt(idPath));
        getBackground();
    }, [location, getBackground])

    const handleBeforeUpload = (file: File) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onloadend = () => {
            setImageData(reader.result as ArrayBuffer);
        };
        return false;
    };

    const handleUpload = (info: UploadChangeParam) => {
        const { status } = info.file;
        if (status === 'done') {
            message.success(`${info.file.name} uploaded successfully`);
        } else if (status === 'error') {
            message.error(`${info.file.name} upload failed.`);
        }
    };

    const handleSubmit = (values: any) => {
        const formData = new FormData();
        formData.append("id", values.id);
        formData.append("bgTitle", values.bgTitle);
        formData.append("isActive", values.isActive);
        if (imageData) {
            const imageBlob = new Blob([imageData], { type: 'image/png' });
            formData.append('img', imageBlob);
            axios({
                url: base_url() + "/api/v1/admin/changeBackgroundStatus",
                method: "POST",
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": "Bearer " + localStorage.getItem("token")
                }
            }).then((response) => {
                setSubmit(true);
            }).catch((err) => {
                const status = err.response.status;
                if (status === 401) {
                    localStorage.setItem("isAuth", "false");
                }
            });
        }
    };

    if (localStorage.getItem('isAuth') === "true") {
        if (isSubmit) {
            return <Navigate to="/admin/admin_background" />
        }

        return (
            <div className="login-container">
                <div className="promo_form_col_4">
                    <Form
                        form={form}
                        onFinish={handleSubmit} layout="vertical">
                        <Form.Item noStyle name="id">
                            <Input type="hidden" />
                        </Form.Item>
                        <Form.Item label="Название" name="bgTitle">
                            <Input id="bgTitle" />
                        </Form.Item>
                        <Form.Item label="Статус" name="isActive">
                            <Select>
                                <Select.Option value={1}>Активировать</Select.Option>
                                <Select.Option value={2}>Отключить</Select.Option>
                            </Select>
                        </Form.Item>
                        {imageUrl ? (
                            <>
                                <Image src={imageUrl} width={200} /><br /><br />
                            </>
                        ) : null}
                        <Form.Item name="image">
                            <Upload
                                beforeUpload={handleBeforeUpload}
                                onChange={handleUpload}
                                fileList={fileList}>
                                <Button icon={<UploadOutlined />}>Загрузить</Button>
                            </Upload>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="feedback__item">
                                Сохранить
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div >
        );
    } else {
        return (
            <Navigate to="/login" />
        )
    }
};

export default EditBackground;
